

















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import CrewMemberCertificateCreateDialog from "@/components/dialogs/CrewMemberCertificateCreateDialog.vue";
import CrewMemberCertificateUpdateDialog from "@/components/dialogs/CrewMemberCertificateUpdateDialog.vue";
import Api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { CrewMemberCertificateModel, CrewMemberModel } from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import UserModule from "@/store/userModule";
import CrewMemberProfilePicture from "@/components/crew-member/CrewMemberProfilePicture.vue";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    CrewMemberCertificateCreateDialog,
    CrewMemberCertificateUpdateDialog,
    CrewMemberProfilePicture
  }
})
export default class CrewMemberCertificates extends Vue {
  @Prop(String) private crewMemberId!: string;

  private createDialog = false;
  private editDialog = false;
  private crewMemberCertificateId = "";
  private loading = false;
  private showArchived = false;
  private items: Array<CrewMemberCertificateModel> = [];
  private search = "";
  private headers: Array<DataTableHeader<CrewMemberCertificateModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Filename", value: "filename" },
    { text: "Certificate Type", value: "certificateTypeName" },
    { text: "Reference", value: "reference" },
    { text: "Start", value: "startDate" },
    { text: "Expiry", value: "expiryDate" },
    { text: "Created", value: "createdUtc" },
    { text: "Dealt With", value: "dealtWith" }
  ];
  private model: CrewMemberModel = {
    countryId: "",
    agencyId: "",
    firstname: "",
    lastname: "",
    dateOfBirth: "",
    passportNumber: "",
    passportExpiry: "",
    employmentStartDate: "",
    employmentEndDate: "",
    seamansBookNumber: "",
    seamansBookIssued: "",
    seamansBookExpiry: "",
    rating: 1,
    placeOfBirth: "",
    contactNumber: "",
    alternativeContactNumber: "",
    nextOfKinName: "",
    nextOfKinContactNumber: "",
    jobRoles: []
  };

  private get superAdminRole() {
    return userModule.role == "ADM";
  }

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  get getCrewProfilePicture() {
    return this.model.profilePictureId;
  }

  private async created() {
    await this.getCertificates();
    await this.getCrewMember();
  }

  private async getCrewMember() {
    const response = await Api.CrewMemberService.apiCrewmemberCrewMemberIdGet(
      this.$route.params.crewMemberId
    );

    this.model = response.data;
  }

  private async getCertificates() {
    try {
      const response = await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCrewMemberIdGet(
        this.crewMemberId
      );

      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch certificates");
    }
  }

  private onCreateCertificate() {
    this.createDialog = true;
  }

  private async onEditCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.crewMemberCertificateId) {
      return;
    }

    this.crewMemberCertificateId = certificate.crewMemberCertificateId;
    this.editDialog = true;
  }

  private async onDownloadCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      certificate.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, certificate.filename);
  }

  private async onDeleteCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.crewMemberCertificateId) {
      return;
    }

    try {
      this.loading = true;
      await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCertificateIdDelete(
        certificate.crewMemberCertificateId
      );
      snackbarModule.setSnackbarMessage("Certificate deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete certificate");
    } finally {
      this.loading = false;
    }

    await this.getCertificates();
  }

  private async updateCertificateDealtWith(item: CrewMemberCertificateModel) {
    if (!item.crewMemberCertificateId) {
      return;
    }
    try {
      await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCertificateIdPut(
        item.crewMemberCertificateId,
        item
      );
      snackbarModule.setSnackbarMessage("Certificate updated");
      this.$emit("certificate-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update certificate");
    } finally {
      this.getCertificates();
    }
  }

  private async archiveCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.crewMemberCertificateId) {
      return;
    }

    try {
      await Api.CrewMemberCertificateService.apiCrewmembercertificateArchiveCrewMemberCertificateIdDelete(
        certificate.crewMemberCertificateId
      );
      snackbarModule.setSnackbarMessage("Certificate Archived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to archive certificate");
    }
    this.getCertificates();
  }

  private async unarchiveCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.crewMemberCertificateId) {
      return;
    }

    try {
      await Api.CrewMemberCertificateService.apiCrewmembercertificateUnarchiveCrewMemberCertificateIdDelete(
        certificate.crewMemberCertificateId
      );
      snackbarModule.setSnackbarMessage("Certificate Unarchived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to unarchive certificate");
    }
    this.getCertificates();
  }

  get filteredCertificates(): Array<CrewMemberCertificateModel> {
    if (this.items.length > 0) {
      return this.items.filter(x => x.archived == this.showArchived);
    }
    return [];
  }
}
